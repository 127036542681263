@import "feed-search";

@include media-breakpoint-down(sm) {
  .d-smed-none {
    display: none !important;
  }

  .d-smed-inline-flex {
    display: inline-flex !important;
  }
}

@include media-breakpoint-down(lg) {
  .navbar-brand {
    padding: 4px;
    align-self: start;
  }

  .toggle-search-sub-header {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    line-height: 1;

    p {
      margin: 0;
      font-weight: bold;
      color: $textPrimary;
    }

    // Get rid of these state's styles on mobile
    &:active,
    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}

header {
  padding: 5px 25px 0;

  @include media-breakpoint-up(lg) {
    padding: 17px 17px 0 17px;

    &.gated-header {
      padding: 0px 17px 17px 17px;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 5px 5px 5px 16px;
  }

  .navbar {
    padding-bottom: 0;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  .side-segment {
    width: 325px;

    @media screen and (max-width: 1120px) {
      width: 260px;
    }

    @media screen and (max-width: 900px) {
      width: auto;
      flex-grow: 1;
    }

    @media screen and (max-width: 900px) {
      &.search-segment {
        justify-content: start !important;
      }
    }
  }

  .center-nav {
    .custom-icon {
      margin-bottom: 0.5rem;
    }

    .active-icon {
      display: none !important;
    }
    .inactive-icon {
      display: inline-block !important;
    }

    .offset {
      margin-bottom: 0.425rem;
    }

    .nav-item {
      margin: 0px 12px;

      @media screen and (max-width: 980px) {
        margin: 0;
      }
    }

    &.navbar-nav .nav-link {
      text-align: center;
      font-family: $font-family-sans-serif-medium;
      font-size: 13px;
      border-bottom: 3px solid white;

      padding: 0.3rem 1rem;

      @media screen and (max-width: 890px) {
        padding: 0.3rem 0.5rem;
      }
      &.active {
        color: $denim-blue;
        border-bottom-color: $denim-blue;

        .active-icon {
          display: inline-block !important;

          path {
            fill: $denim-blue;
          }
        }
        .inactive-icon {
          display: none !important;
        }
      }
    }
  }

  .dropdown-more {
    cursor: pointer;

    .dropdown-toggle-button {
      line-height: 1;
    }
  }

  .dropdown-menu-scrollable {
    max-height: 62.5vh;
    overflow-y: scroll;
  }
}

.navbar-light .navbar-nav .nav-link.active {
  color: $denim-blue;
}

.header--shadowed {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  flex-shrink: 0;
}

.navbar-brand,
.navbar-brand-white {
  text-indent: -10000px;
  width: 5rem;
  background: url("../../../../../images/figure1_logo.svg") no-repeat;
  background-size: contain;
  background-position: center;
}

.navbar-brand-white {
  background: url("../../../../../images/figure1_logo_white.svg") no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 0;
}

.nav-divider {
  background: $light-gray;
  width: 1px;
  height: 1.2rem;
}

.dropdown-profile {
  .dropdown-menu {
    &--pointer-top-right {
      @include dropdown-menu-pointer(8px, 8.25rem);
    }
  }
}

.gated-profile-mobile-placeholder-avatar {
  height: 30px;
  width: 30px;
}
