@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";
@import "../../assets/scss/all/custom/colors";
@import "../../assets/scss/all/custom/variables";

.case-tag {
  padding: 5px 8px;
  margin-right: 10px;
  border: 1px solid $darker-green;
  border-radius: 4px;
  display: inline;
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: bold;
  color: $darker-green;
}

.case-detail-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0 20px;
}

.case-detail-label-area {
  padding: 0 0 20px 20px;
}

.icomoon {
  font-family: "icomoon";
}

/**** Card ***/
.card-button-primary {
  height: fit-content;
  width: fit-content;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $btn-primary-color;
  background-color: $btn-primary-bg-color;
  border-radius: $btn-primary-border-radius;
  border: 0;
}

.card-button-sm {
  padding: 11px 16px;
}

/************** HEADER *********************/

.paging-container {
  width: 15px;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 12px;
}

/************** AUTHOR CARD *********************/

.case-author-bio {
  padding: 0px 25px 25px 20px;
  opacity: 0.9;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1a252b;
}

/************** COMMENTS *********************/

/******** ONBOARDING SLIDES CAROUSEL *********/
.figure1-onboarding-slides-carousel {
  padding-bottom: 30px;
  width: map-get($map: $sizes, $key: 50);

  @include media-breakpoint-down(sm) {
    width: map-get($map: $sizes, $key: 100);
  }

  .carousel-item {
    text-align: center;

    // Dimensions change to show at least some text above fold. Used to be 500x500
    .lottie-animation {
      height: 450px;
      width: 450px;

      @include media-breakpoint-down(sm) {
        height: 400px;
        width: 400px;
      }
    }

    .slide-text {
      padding: 0 map-get($map: $spacers, $key: 5);
      margin: 0;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }
}

/************** CAROUSEL *********************/

.figure1-carousel {
  padding-bottom: 30px;

  .carousel-item {
    text-align: center;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23E6E6E6' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23E6E6E6' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators li {
  background-color: #e6e6e6 !important;
}

.carousel-indicators {
  bottom: -15px !important;
}

.carousel-background {
  max-width: 100%;
  height: 500px;
  background: #999999;
}

/************** DROP DOWN *********************/

.case-dropdown-item {
  display: flex;
  flex-direction: row;
}

.case-dropdown-text-area {
  padding-left: 5px;
}

.case-dropdown-text-primary {
  width: 193px;
  height: 21px;
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1a252b;
}

.case-dropdown-text-secondary {
  width: 211px;
  min-height: 21px;
  opacity: 0.9;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #6b778b;
  white-space: normal;
}

.disabled {
  .case-dropdown-text-primary,
  .case-dropdown-text-secondary {
    color: $gray-500;
  }
}

/************** SKELETON *********************/

.skeleton-header {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.skeleton-header-name {
  margin-left: 5px;
  width: 100%;
}

.skeleton-header-line {
  display: block;
}

/*************** SIMILAR CASES ******************/
.similar-cases-link {
  color: $body-color;
}
