@import "../custom/variables";

.react-select {
  &.is-invalid {
    & > div:first-of-type,
    & > div:first-of-type:hover {
      box-shadow: 0 0 4px 1px #ed1c30; // pulled from bootstrap, not importing because _everything breaks_
      border-color: white;
    }
  }

  &.is-invalid ~ .invalid-feedback {
    display: block;
  }
}
