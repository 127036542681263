@import "../../assets/scss/style";

.filter-modal {
  display: inline;
  // Override Reactstrap's Dropdown position so we can control
  // which component this is relative to.
  position: static !important;

  .dropdown-menu {
    width: 220px;
  }
  // Need this so it doesn't cover the mobile header.
  .custom-dropdown-menu {
    top: 60px !important;
  }

  .filter-modal-toggle-button {
    border: none !important;
    background-color: transparent !important;
    padding: 0;
  }

  .filter-modal-filter-list {
    transform: none !important;
    top: auto !important;
  }

  .actions {
    border-top: 1px solid $lighter-gray;
  }
}
