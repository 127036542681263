.btn-secondary,
.btn-denim-blue-light {
  color: theme-color("denim-blue");

  @include hover {
    color: theme-color("denim-blue");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: darken(theme-color("denim-blue"), 12.5%);
  }
}

.btn-aqua {
  background-color: theme-color("aqua");
  color: white;

  @include hover {
    background-color: theme-color("aqua");
    color: white;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: theme-color("aqua");
    color: white;
  }
}

@each $color, $value in $theme-colors {
  .btn-text.btn-#{$color} {
    background: transparent;
    border: 0;

    @include button-outline-variant(
      $value,
      $value,
      $active-background: rgba($value, 0.2),
      $active-border: transparent
    );

    &:hover {
      svg {
        fill: color-yiq($value);
      }
    }
  }

  .btn-outline-#{$color} {
    &:hover {
      svg {
        fill: color-yiq($value);
      }
    }
  }
}

button:focus {
  outline: none;
}

.btn-icon {
  padding: 0;
  border: 0;
  appearance: none;
}

$btn-circle-size: $btn-font-size * $btn-line-height + $btn-padding-y * 2;
$btn-circle-size-xs: (
    $btn-font-size-sm * $btn-line-height-sm + $btn-padding-y-sm * 2
  ) * 0.9;
$btn-circle-size-sm: $btn-font-size-sm * $btn-line-height-sm + $btn-padding-y-sm *
  2;
$btn-circle-size-lg: $btn-font-size-lg * $btn-line-height-lg + $btn-padding-y-lg *
  2;

.btn-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: $btn-circle-size;
  height: $btn-circle-size;

  &.btn-xs {
    width: $btn-circle-size-xs;
    height: $btn-circle-size-xs;
  }

  &.btn-sm {
    width: $btn-circle-size-sm;
    height: $btn-circle-size-sm;
  }

  &.btn-lg {
    width: $btn-circle-size-lg;
    height: $btn-circle-size-lg;
  }
}

.download-button,
.download-button-disabled {
  font-family: $font-family-sans-serif-bold;
  background-color: #eef1f7;
  color: #3f6296;
  height: 28px;
  border-radius: 4px;
  padding: 4px 9px;

  &:hover {
    background-color: #3f6296;
    color: #eef1f7;

    svg path {
      fill: #eef1f7;
    }
  }
}

.download-button-disabled {
  $color: rgb(107, 119, 139);

  color: $color;

  svg path {
    fill: $color;
  }

  &:hover {
    background-color: $color;
  }
}

.btn-back.btn-theme-dark {
  background-color: #353a43;
  border-color: #353a43;
  color: #eef1f7;
}
