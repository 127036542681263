/********************** UTILITIES **********************************/

// The following $utilities generation is from Bootstrap's 5 utility API.
// For info around adding additional utilities and the various options see:
// https://getbootstrap.com/docs/5.0/utilities/api/

$utilities: ();

/********************** LAYOUT **************************************/

$spacer-utilities: map-merge($spacers, (-2: spacer(2) * -1));

$utilities: map-merge(
  $utilities,
  (
    "top": (
      property: top,
      class: t,
      responsive: true,
      values:
        map-merge(
          $spacer-utilities,
          (
            "45": 45%
          )
        )
    ),
    "right": (
      property: right,
      class: r,
      values:
        map-merge(
          $spacer-utilities,
          (
            "100": 100%
          )
        )
    ),
    "bottom": (
      property: bottom,
      class: b,
      responsive: true,
      values:
        map-merge(
          $spacer-utilities,
          (
            "auto": auto
          )
        )
    ),
    "left": (
      property: left,
      responsive: true,
      class: l,
      values:
        map-merge(
          $spacer-utilities,
          (
            initial: initial,
            "100": 100%,
            "30px": px-to-rem(30px),
            "40px": px-to-rem(40px),
            "50px": px-to-rem(50px)
          )
        )
    ),
    "overflow-y": (
      property: overflow-y,
      responsive: true,
      values: auto overlay
    ),
    "position": (
      property: position,
      responsive: true,
      values: fixed
    ),
    "z-index": (
      property: z-index,
      class: z,
      values: (
        1: 1,
        2: 2,
        auto: auto,
        dropdown: $zindex-dropdown,
        sticky: $zindex-sticky,
        fixed: $zindex-fixed,
        backdrop: $zindex-modal-backdrop,
        modal: $zindex-modal,
        popover: $zindex-popover,
        tooltip: $zindex-tooltip
      )
    ),
    "column-count": (
      property: column-count,
      responsive: true,
      values: 1 2
    ),
    "column-fill": (
      property: column-fill,
      values: initial
    ),
    "column-gap": (
      property: column-gap,
      values: $spacers
    ),
    "display": (
      property: display,
      class: d,
      responsive: true,
      values: grid
    ),
    "grid-template-columns": (
      property: grid-template-columns,
      class: grid-cols,
      values: (
        3: repeat(3, minmax(0, 1fr))
      )
    ),
    "grid-gap": (
      property: gap,
      values: $spacers
    ),
    "object-fit": (
      property: object-fit,
      class: object,
      values: contain cover fill none scale-down
    )
  )
);

/********************** SIZING **************************************/

$utilities: map-merge(
  $utilities,
  (
    "height": (
      property: height,
      class: h,
      values: (
        "25px": px-to-rem(25px),
        "30px": px-to-rem(30px),
        "35px": px-to-rem(35px),
        "36px": px-to-rem(36px),
        "40px": px-to-rem(40px),
        "70px": px-to-rem(70px),
        "80px": px-to-rem(80px),
        "180px": px-to-rem(180px),
        "200px": px-to-rem(200px),
        "400px": px-to-rem(400px),
        "screen": 100vh
      )
    ),
    "width": (
      property: width,
      class: w,
      values: (
        "36px": px-to-rem(36px),
        "90px": px-to-rem(90px),
        "125px": px-to-rem(125px),
        "215px": px-to-rem(215px),
        "295px": px-to-rem(295px),
        "screen": 100vw,
        "fit-content": fit-content
      )
    ),
    "max-height": (
      property: max-height,
      class: mh,
      values: (
        "80px": px-to-rem(80px),
        "300px": px-to-rem(300px)
      )
    ),
    "max-width": (
      property: max-width,
      class: mw,
      values: (
        "491": px-to-rem(491px),
        "499": px-to-rem(499px),
        "75": 75%
      )
    ),
    "min-width": (
      property: min-width,
      class: min-w,
      values: (
        "96px": px-to-rem(96px),
        "130px": px-to-rem(130px),
        "171px": px-to-rem(171px)
      )
    )
  )
);

/********************** TYPOGRAPHY **********************************/

$utilities: map-merge(
  $utilities,
  (
    "font-size": (
      property: font-size,
      class: text,
      values: $text-sizes
    ),
    "color": (
      property: color,
      class: text,
      values: (
        current: currentColor
      )
    ),
    "letter-spacing": (
      property: letter-spacing,
      values: normal
    ),
    "line-height": (
      property: line-height,
      values: $line-heights
    ),
    "white-space": (
      property: white-space,
      class: whitespace,
      values: pre-line
    ),
    "text-decoration": (
      property: text-decoration,
      class: text,
      values: underline
    )
  )
);

/********************** BORDERS *************************************/

$utilities: map-merge(
  $utilities,
  (
    "border-width": (
      property: border-width,
      values: (
        0: 0,
        1: px-to-rem(1px),
        2: px-to-rem(2px),
        3: px-to-rem(3px),
        4: px-to-rem(4px),
        5: px-to-rem(5px)
      )
    )
  )
);

/********************** EFFECTS *************************************/

$utilities: map-merge(
  $utilities,
  (
    "opacity": (
      property: opacity,
      values: (
        20: 0.2,
        40: 0.4,
        90: 0.9
      )
    )
  )
);

/********************** TRANSFORMS ***********************************/

$utilities: map-merge(
  $utilities,
  (
    "rotate": (
      property: transform,
      class: rotate,
      values: (
        90: rotate(90deg),
        180: rotate(180deg),
        270: rotate(270deg)
      )
    )
  )
);

/********************** INTERACTIVITY *******************************/

$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: pointer
    ),
    "pointer-events": (
      property: pointer-events,
      values: none
    ),
    "resize": (
      property: resize,
      values: none
    )
  )
);

// prettier-ignore
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Loop over each utility property
    @each $key, $utility in $utilities {
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Only proceed if responsive media queries are enabled or if it's the base media query
      @if type-of($utility) == "map" and (map-get($utility, responsive) or $infix == "") {
        // @include generate-utility-class($utility, $infix);
        @include generate-utility($utility, $infix);
      }
    }
  }
}

/******************* SQUARE IMAGE (THUMBNAIL) ***********************/

.square-image,
.rounded-square-image {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}

.rounded-square-image img,
.square-image img,
.rounded-square-image > .image,
.square-image > .image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/******************* ROUNDED SQUARE IMAGE (THUMBNAIL) ***********************/

.rounded-square-image {
  border-radius: 4px;
}

/*************************** ICON COLORS ****************************/

@each $color, $value in $theme-colors {
  .icon-#{$color} {
    path {
      fill: $value;
    }
  }
}

/*************************** CUSTOM TYPOGRAPHY **********************/

// Create utility functions to clamp a paragraph tag to a certain
// number of lines based on provided line-height.
@each $line-height-key, $line-height-value in $line-heights {
  @each $max-lines in $text-clamp-max-lines {
    .text-clamp-#{$line-height-key}-#{$max-lines} {
      max-height: $line-height-value * $max-lines;
      line-height: $line-height-value + 0.05;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: $max-lines;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;
    }
  }
}

@each $color, $value in $theme-colors {
  .placeholder-#{$color} {
    &::placeholder {
      color: $value;
      opacity: 1;
    }
  }
}

/**************** Text divider *********************/

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  min-width: 200px;
  width: 100%;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid white;
}

.separator:not(:empty)::before {
  margin-right: 0.35em;
  margin-top: 0.2rem;
}

.separator:not(:empty)::after {
  margin-left: 0.35em;
  margin-top: 0.2rem;
}

/************ Alternates to alerts ****************/

.warning-box {
  border-radius: 4px;
  border: 2px solid $red;
  padding: 2px;
  text-align: center;
}
