.dropdown {
  .dropdown-toggle {
    display: flex;
  }

  .dropdown-toggle:after {
    border-top: 0;
    content: "\e912";
    font-size: 0.75rem;
    align-self: center;
    font-family: icomoon;
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.38);

    &--center {
      right: auto !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: calc(100% + 6px) !important;
    }

    &--pointer-top-center {
      @include dropdown-menu-pointer(8px, 50%);
    }
  }
}

@each $color, $value in $theme-colors {
  .dropdown-#{$color} {
    .dropdown-item {
      color: theme-color($color);
    }
  }
}
