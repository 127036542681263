@import "../../assets/scss/all/custom/colors";

.deleted-container {
  background: $light-white;
  color: $textPrimary;
  padding: 1rem;
  margin: 1rem 0px;
  font-size: 15px;
  border-radius: 6px;
  text-align: center;
}

.comment {
  word-break: break-word;
}
