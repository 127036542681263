.media-overlay-container {
  min-height: 400px;
}
.media-overlay {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 74%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .cta-container {
    max-width: 80%;
  }
}
