@import "../../assets/scss/style";
@import "./NavStyles";

p {
  font-size: 13px;
}

.feed-sidebar-filter-container {
  background-color: #fff;
  position: relative;
  border-radius: 2px;

  .section-header {
    font-size: 14px;
    padding: 0.75rem;
    border-bottom: solid 1px $lighter-gray;
    margin-bottom: 0;
  }

  .filter-tags-container {
    display: flex;
    padding: map-get($map: $spacers, $key: 2);
    padding-left: 0;
    padding-right: 0;
    flex-wrap: wrap;

    .filter-tag {
      padding: map-get($map: $spacers, $key: 1);
      border: solid 1px $lighter-gray;
      margin-bottom: 0;
      color: $textSecondary;
      border-radius: 4px;
      margin-right: map-get($map: $spacers, $key: 1);
    }

    &.empty {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .no-filters-text {
      color: $textSecondary;
      margin-bottom: map-get($map: $spacers, $key: 2);
    }

    .add-more {
      display: flex;
      align-items: center;

      p {
        margin-left: map-get($map: $spacers, $key: 1);
        margin-bottom: 0;
        color: $denim-blue;
      }
    }
  }

  .filter-actions {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-bottom: 0;
      color: $denim-blue;
    }

    .clear-filters-button {
      background-color: transparent;
      border: none;
      color: $denim-blue;
    }
  }
}
